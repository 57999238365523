import React, { Fragment, useEffect, useState, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import Toaster from "components/Toaster";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import {
  useCountries,
  useDateFormats,
  useLanguages,
  useTimeFormats,
  useTimeZones,
  useUnitSystems
} from "../../../hooks/hooks";

import {
  UsersRootPath,
  UsersAssetsCreatePath,
  UsersAssetsUpdatePath,
  UsersAssetsRolesPath
} from "routes/paths";

import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { SpinnerContext } from "provider/SpinnerProvider";
import { UserContext } from "provider/UserProvider";

import MainBarDashboard from "components/MainBarDashboard";
import DialogModal from "components/DialogModal";
import TextField from "components/TextField";
import Dropdown from "components/MUIDropdown";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import PageHeader from "components/PageHeader";
import PageHeaderDetailItem from "components/PageHeaderDetailItem";
import PageHeaderRightItem from "components/PageHeaderRightItem";
import ExpansionPanel from "components/ExpansionPanel";
import ViewTreeView from "components/ViewTreeView";
import PaperHeading from "components/PaperHeading";

import {
  getOptions,
  hierarchyContainer,
  isValid,
  getValidArrayValue
} from "utils";

import { IDateFormat, ITimeZone, IAsset } from "components/type";
import { FormErrorMessages, DateFormatType } from "constants/enum";

import {
  updateUser as updateUserDetails,
  getDefaultValuesByCountryId,
  getUser,
  getAssociatedAssets,
  deleteAssociatedAssets,
  getAzureAdUser
} from "../../../actions/usersPageActions";
import useLoggedInUserDetails from "hooks/useLoggedInUserDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  tabs: {
    flexGrow: 1,
    marginBottom: theme.spacing(3)
  },
  skeleton: {
    margin: theme.spacing(2),
    width: "100%"
  }
}));

const UserDetails = () => {
  const { isAdmin } = useLoggedInUserDetails();
  const { userId } = useParams<{ userId: string }>();
  const [
    showUpdateDefaultConfirmationModal,
    setShowUpdateDefaultConfirmationModal
  ] = useState(false);

  const [associatedAssetDialog, setAssociatedAssetDialog] = useState(false);
  const [deleteAsset, setDeleteAsset] = useState("");
  const [deleteDialogTitle, setDeleteDialogTitle] = useState("");

  const classes = useStyles();

  const { register, handleSubmit, errors, setValue, watch, control } =
    useForm();

  const [user, setUser] = useState<any>();
  const { associateUserContext } = useContext(UserContext);
  const [, setAssociateUser] = associateUserContext;

  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [isUserDataCompleted, setIsUserDataCompleted] = useState(false);
  const [isUserAssetsCompleted, setIsUserAssetsCompleted] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [countryOptions, setCountryOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [shortDateFormatOptions, setShortDateFormatOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [longDateFormatOptions, setLongDateFormatOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [userTimeZoneOptions, setUserTimeZoneOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [preferredTimeZoneOptions, setPreferredTimeZoneOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [timeFormatOptions, setTimeFormatOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [unitSystemsOptions, setUnitSystemsOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [languageOptions, setLanguageOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [userAsset, setUseAsset] = useState<IAsset[]>([]);

  const [isDateFormatsCompleted, setIsDateFormatsCompleted] = useState(false);
  const dateFormats = useDateFormats(() => setIsDateFormatsCompleted(true));

  const [isCountriesCompleted, setIsCountriesCompleted] = useState(false);
  const countries = useCountries(() => setIsCountriesCompleted(true));

  const [isLanguagesCompleted, setIsLanguagesCompleted] = useState(false);
  const languages = useLanguages(() => setIsLanguagesCompleted(true));

  const [isTimeFormatsCompleted, setIsTimeFormatsCompleted] = useState(false);
  const timeFormats = useTimeFormats(() => setIsTimeFormatsCompleted(true));

  const [isTimeZonesCompleted, setIsTimeZonesCompleted] = useState(false);
  const timeZones = useTimeZones(() => setIsTimeZonesCompleted(true));

  const [isUnitSystemsCompleted, setIsUnitSystemsCompleted] = useState(false);
  const unitSystems = useUnitSystems(() => setIsUnitSystemsCompleted(true));

  const values = watch();

  const areDetailsLoaded = () => {
    return (
      isDateFormatsCompleted &&
      isCountriesCompleted &&
      isLanguagesCompleted &&
      isTimeFormatsCompleted &&
      isTimeZonesCompleted &&
      isUnitSystemsCompleted
    );
  };

  useEffect(() => {
    setIsUserDataCompleted(false);
    setIsUserAssetsCompleted(false);

    if (areDetailsLoaded() && userId) {
      getUser(userId)
        .then((user) => {
          setUser(user);
          setAssociateUser(user);
        })
        .then(() => setIsUserDataCompleted(true))
        .then(() => getAssociatedAssets(userId))
        .then((d) => {
          setUseAsset(d);
          setIsUserAssetsCompleted(true);
        });
    }
  }, [
    isDateFormatsCompleted,
    isCountriesCompleted,
    isLanguagesCompleted,
    isTimeFormatsCompleted,
    isTimeZonesCompleted,
    isUnitSystemsCompleted
  ]);

  useEffect(() => {
    setIsSpinnerVisible(true);

    if (
      user &&
      isUserDataCompleted &&
      isValid(countries) &&
      isValid(dateFormats) &&
      isValid(timeZones) &&
      isValid(timeFormats) &&
      isValid(unitSystems) &&
      isValid(languages)
    ) {
      setValue("firstName", user?.firstName);
      setValue("lastName", user?.lastName);
      setValue("status", user?.status ? "true" : "false");
      setValue("tamId", user?.tamId);
      setValue("b2CObjectId", user?.b2CObjectId);
      setValue("hasPhoneAlert", user?.hasPhoneAlert);
      setValue("hasEmailAlert", user?.hasEmailAlert);
      setValue("primaryPhone", user?.primaryPhone);
      setValue("primaryEmail", user?.primaryEmail);
      setValue("company", user?.company);
      setValue("street", user?.street);
      setValue("city", user?.city);
      setValue("state", user?.state);
      setValue("postalCode", user?.postalCode);
      setValue("alertsPhone", user?.alertsPhone);
      setValue("alertsEmail", user?.alertsEmail);
      setValue("countryId", user?.countryId);
      setValue("shortDateFormatId", user?.shortDateFormatId);
      setValue("longDateFormatId", user?.longDateFormatId);
      setValue("userTimeZone", user?.userTimeZone);
      setValue("preferredTimeZone", user?.preferredTimeZone);
      setValue("languageId", user?.languageId);
      setValue("timeFormatId", user?.timeFormatId);
      setValue("unitSystemId", user?.unitSystemId);
      setIsSpinnerVisible(false);
    }
  }, [
    countries,
    dateFormats,
    languages,
    timeFormats,
    timeZones,
    unitSystems,
    isUserDataCompleted
  ]);

  useEffect(() => {
    if (isValid(countries) && !values.countryId) {
      setCountryOptions(getOptions(countries, "id", "countryName"));
    }
    if (isValid(dateFormats) && !values.shortDateFormatId) {
      const shortDateOptions = getOptions(
        getValidArrayValue(dateFormats).filter(
          (format: IDateFormat) =>
            format.dateFormatType === DateFormatType.short
        ),
        "id",
        "dateFormatName"
      );
      setShortDateFormatOptions(shortDateOptions);
    }
    if (isValid(dateFormats) && !values.longDateFormatId) {
      const longDateOptions = getOptions(
        getValidArrayValue(dateFormats).filter(
          (format: IDateFormat) => format.dateFormatType === DateFormatType.long
        ),
        "id",
        "dateFormatName"
      );
      setLongDateFormatOptions(longDateOptions);
    }
    if (isValid(timeZones) && !values.userTimeZone) {
      setUserTimeZoneOptions(
        getOptions(timeZones, "timeZoneId", "description")
      );
    }
    if (isValid(timeZones) && !values.preferredTimeZone) {
      setPreferredTimeZoneOptions(
        getOptions(timeZones, "timeZoneId", "description")
      );
    }
    if (isValid(timeFormats) && !values.timeFormatId) {
      setTimeFormatOptions(getOptions(timeFormats, "id", "timeFormatName"));
    }
    if (isValid(unitSystems) && !values.unitSystemId) {
      setUnitSystemsOptions(getOptions(unitSystems, "id", "unitSystemName"));
    }
    if (isValid(languages) && !values.languageId) {
      setLanguageOptions(getOptions(languages, "id", "languageName"));
    }
  }, [countries, dateFormats, languages, timeFormats, timeZones, unitSystems]);

  const onSubmit = (data: any) => {
    setIsSpinnerVisible(true);
    data.status = data.status === "true";
    if (user && !isEmpty(user)) {
      data.id = user.id;
      updateUserDetails(data)
        .then((e) => {
          if (userId) {
            getUser(userId).then((user) => {
              setUser(user);
              setAssociateUser(user);
            });
            getAssociatedAssets(userId).then((d) => {
              setUseAsset(d);
            });
          }
          setIsUpdate(false);
          setIsSpinnerVisible(false);
        })
        .catch((e) => {
          Toaster["error"](e, "Updating User Details");
          setIsUpdate(false);
          setIsSpinnerVisible(false);
        });
    }
  };

  const changeToDefaultByCountry = async (countryId: string) => {
    setIsSpinnerVisible(true);
    const defaultValues = await getDefaultValuesByCountryId(countryId);
    const ianaTimeZone = getValidArrayValue(timeZones).filter(
      (tz: ITimeZone, index: number) =>
        tz.timeZoneId === defaultValues.ianaTimeZoneId
    );
    if (ianaTimeZone.length > 0) {
      setValue("userTimeZone", defaultValues.ianaTimeZoneId);
      setValue("preferredTimeZone", defaultValues.ianaTimeZoneId);
    } else {
      setValue("userTimeZone", defaultValues.timeZoneId);
      setValue("preferredTimeZone", defaultValues.timeZoneId);
    }
    setValue("shortDateFormatId", defaultValues.shortDateFormatId);
    setValue("longDateFormatId", defaultValues.longDateFormatId);
    setValue("timeFormatId", defaultValues.timeFormatId);
    setValue("unitSystemId", defaultValues.unitSystemId);
    setIsSpinnerVisible(false);
  };

  const getAccordionTitle = (): string => {
    if (user && !isEmpty(user)) {
      return user.fullName;
    } else {
      return "Loading...";
    }
  };

  const getHierarchyLabel = (item: IAsset) => {
    return item ? `${item.assetName}` : "";
  };
  const getId = (item: IAsset) => {
    return item ? item.hierarchyId : "";
  };

  const breadcrumbs = [
    {
      label: "Users",
      href: UsersRootPath
    },
    {
      label: getAccordionTitle().toString()
    }
  ];

  const switchToUpdate = () => {
    setIsUpdate(true);
  };

  const history = useHistory();

  const goToAssociateNewAsset = () => {
    if (userId) {
      history.push(UsersAssetsCreatePath.replace(":userId", userId));
    }
  };
  const editAssociatedAssets = (hierarchyId: string) => {
    if (userId) {
      return history.push(
        UsersAssetsUpdatePath.replace(":userId", userId).replace(
          ":hierarchyId",
          hierarchyId
        )
      );
    }
  };
  const editAssetsRoles = (hierarchyId: string) => {
    if (userId) {
      return history.push(
        UsersAssetsRolesPath.replace(":userId", userId).replace(
          ":hierarchyId",
          hierarchyId
        )
      );
    }
    return;
  };

  const setDeleteDialog = (id: string, account: string): void => {
    setDeleteAsset(id);
    setDeleteDialogTitle(account);
    setAssociatedAssetDialog(true);
  };

  const removeAssociatedAssets = (): void => {
    setIsSpinnerVisible(true);
    if (userId) {
      deleteAssociatedAssets(userId, deleteAsset).then(() => {
        if (userId) {
          getUser(userId).then((user) => {
            setUser(user);
            setAssociateUser(user);
          });
          getAssociatedAssets(userId).then((d) => {
            setUseAsset(d);
            setIsSpinnerVisible(false);
          });
        }
      });
    }
  };

  const handleGetGlobalUser = () => {
    const email = values.primaryEmail;
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setIsSpinnerVisible(true);
      getAzureAdUser(email)
        .then((e) => {
          if (!e.error) {
            const countryId = getValidArrayValue(countries).filter(
              (x: any) => x.isO3166_2 === e.country
            )[0].id;
            changeToDefaultByCountry(countryId);
            setValue("firstName", e.firstName);
            setValue("lastName", e.lastName);
            setValue("tamId", e.tamId);
            setValue("b2CObjectId", e.id);
            setValue("countryId", countryId);
          } else {
            Toaster["error"](e.error, "Error validating global user");
          }
          setIsSpinnerVisible(false);
        })
        .catch((e) => {
          Toaster["error"](e.error, "Error validating global user");
          setIsSpinnerVisible(false);
        });
    } else {
      Toaster["error"](`Invalid email :${email}`);
    }
  };

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <>
        <PageHeader
          title={user ? `${user.firstName} ${user.lastName}` : ""}
          subTitle={user && user.company ? user.company : ""}
          details={
            <Fragment>
              <PageHeaderDetailItem
                label="ID"
                value={!isEmpty(user) ? user.tamId : ""}
              />
            </Fragment>
          }
        >
          <PageHeaderRightItem
            label="Status"
            value={!isEmpty(user) ? (user.status ? "Active" : "Inactive") : ""}
          />
          <PageHeaderRightItem
            label="Email"
            value={!isEmpty(user) ? user.primaryEmail : ""}
          />
        </PageHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <div className={classes.root}>
                <ExpansionPanel label="General">
                  <TextField
                    readOnly={!isUpdate}
                    error={errors.firstName}
                    label="First Name"
                    name="firstName"
                    inputRef={register({
                      required: FormErrorMessages.fieldRequired
                    })}
                  />
                  <TextField
                    readOnly={!isUpdate}
                    error={errors.lastName}
                    label="Last Name"
                    name="lastName"
                    inputRef={register({
                      required: FormErrorMessages.fieldRequired
                    })}
                  />
                  <Dropdown
                    readOnly={!isUpdate}
                    label="Status"
                    name="status"
                    rules={{ required: true }}
                    data={[
                      {
                        label: "Active",
                        value: "true"
                      },
                      {
                        label: "Inactive",
                        value: "false"
                      }
                    ]}
                    control={control}
                    error={errors.status}
                  />
                  <TextField
                    readOnly={!isUpdate}
                    error={errors.tamId}
                    label="TAM Id"
                    name="tamId"
                    inputRef={register({
                      required: false
                    })}
                  />
                  <TextField
                    readOnly={!isUpdate}
                    error={errors.b2CObjectId}
                    label="B2C Object Id"
                    name="b2CObjectId"
                    inputRef={register({
                      required: false
                    })}
                  />
                  <TextField
                    readOnly={!isUpdate}
                    error={errors.primaryPhone}
                    label="Primary Phone"
                    name="primaryPhone"
                    inputRef={register({
                      required: false
                    })}
                  />

                  <div className="row">
                    <div className="col-sm-10" style={{ paddingRight: "1px" }}>
                      <TextField
                        readOnly={!isUpdate}
                        error={errors.primaryEmail}
                        label="Primary Email"
                        name="primaryEmail"
                        inputRef={register({
                          required: FormErrorMessages.fieldRequired,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: FormErrorMessages.invalidEmail
                          }
                        })}
                      />
                    </div>
                    <div className="col-sm-2" style={{ paddingTop: "5px" }}>
                      <Tooltip title="Validate email">
                        <IconButton
                          disabled={!isUpdate}
                          aria-label="validate"
                          onClick={() => handleGetGlobalUser()}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>

                  <TextField
                    readOnly={!isUpdate}
                    error={errors.company}
                    label="Company"
                    name="company"
                    inputRef={register({
                      required: false
                    })}
                  />

                  <TextField
                    readOnly={!isUpdate}
                    error={errors.street}
                    label="Street"
                    name="street"
                    inputRef={register({
                      required: false
                    })}
                  />

                  <TextField
                    readOnly={!isUpdate}
                    error={errors.city}
                    label="City"
                    name="city"
                    inputRef={register({
                      required: false
                    })}
                  />

                  <TextField
                    readOnly={!isUpdate}
                    error={errors.state}
                    label="State"
                    name="state"
                    inputRef={register({
                      required: false
                    })}
                  />

                  <TextField
                    readOnly={!isUpdate}
                    error={errors.postalCode}
                    label="Postal Code"
                    name="postalCode"
                    inputRef={register({
                      required: false
                    })}
                  />
                </ExpansionPanel>
                <ExpansionPanel label="Details">
                  <Dropdown
                    readOnly={!isUpdate}
                    label="Country"
                    name="countryId"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={countryOptions}
                    control={control}
                    error={errors.countryId}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      if (user && !isEmpty(user)) {
                        setShowUpdateDefaultConfirmationModal(true);
                      } else {
                        changeToDefaultByCountry(e.target.value as string);
                      }
                    }}
                  />
                  <Dropdown
                    readOnly={!isUpdate}
                    label="Short Date Format"
                    name="shortDateFormatId"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={shortDateFormatOptions}
                    control={control}
                    error={errors.shortDateFormatId}
                    changed={
                      user &&
                      values.shortDateFormatId !== user.shortDateFormatId
                    }
                  />
                  <Dropdown
                    readOnly={!isUpdate}
                    label="Long Date Format"
                    name="longDateFormatId"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={longDateFormatOptions}
                    control={control}
                    error={errors.longDateFormatId}
                    changed={
                      user && values.longDateFormatId !== user.longDateFormatId
                    }
                  />
                  <Dropdown
                    readOnly={!isUpdate}
                    label="User Timezone"
                    name="userTimeZone"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={userTimeZoneOptions}
                    control={control}
                    error={errors.userTimeZone}
                    changed={user && values.userTimeZone !== user.userTimeZone}
                  />
                  <Dropdown
                    readOnly={!isUpdate}
                    label="Preferred Timezone"
                    name="preferredTimeZone"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={preferredTimeZoneOptions}
                    control={control}
                    error={errors.preferredTimeZone}
                    changed={
                      user &&
                      values.preferredTimeZone !== user.preferredTimeZone
                    }
                  />
                  <Dropdown
                    readOnly={!isUpdate}
                    label="Time Format"
                    name="timeFormatId"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={timeFormatOptions}
                    control={control}
                    error={errors.timeFormatId}
                    changed={user && values.timeFormatId !== user.timeFormatId}
                  />
                  <Dropdown
                    readOnly={!isUpdate}
                    label="Unit System"
                    name="unitSystemId"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={unitSystemsOptions}
                    control={control}
                    error={errors.unitSystemId}
                    changed={user && values.unitSystemId !== user.unitSystemId}
                  />
                  <Dropdown
                    readOnly={!isUpdate}
                    label="Language"
                    name="languageId"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={languageOptions}
                    control={control}
                    error={errors.languageId}
                  />
                </ExpansionPanel>
                <ExpansionPanel label="Alerts">
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Notification Preference
                    </FormLabel>
                    <FormGroup aria-label="position" row>
                      <Controller
                        as={
                          <FormControlLabel
                            disabled={!isUpdate}
                            control={<Checkbox color="primary" />}
                            label="Phone Alert"
                            labelPlacement="end"
                          />
                        }
                        name="hasPhoneAlert"
                        control={control}
                        defaultValue={false}
                      />
                      <Controller
                        as={
                          <FormControlLabel
                            disabled={!isUpdate}
                            control={<Checkbox color="primary" />}
                            label="Email Alert"
                            labelPlacement="end"
                          />
                        }
                        name="hasEmailAlert"
                        control={control}
                        defaultValue={false}
                      />
                    </FormGroup>
                  </FormControl>
                  <TextField
                    readOnly={!isUpdate}
                    error={errors.alertsPhone}
                    label="Alerts Phone"
                    name="alertsPhone"
                    inputRef={register({
                      required: {
                        value:
                          values.hasPhoneAlert && values.primaryPhone === "",
                        message: FormErrorMessages.fieldRequired
                      }
                    })}
                  />
                  <TextField
                    readOnly={!isUpdate}
                    error={errors.alertsEmail}
                    label="Alerts Email"
                    name="alertsEmail"
                    inputRef={register({
                      required: {
                        value: values.hasEmailAlert,
                        message: FormErrorMessages.fieldRequired
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: FormErrorMessages.invalidEmail
                      }
                    })}
                  />
                </ExpansionPanel>
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              {!isUserAssetsCompleted ? (
                <Skeleton />
              ) : (
                <>
                  {!isEmpty(userAsset) &&
                    userAsset.map((item) => {
                      return (
                        <ExpansionPanel
                          key={item.hierarchyId}
                          label={item.assetName}
                          defaultExpanded={false}
                          expansionActionButtons={isAdmin ? [
                            {
                              buttonLabel: "Roles",
                              color: "secondary",
                              action: (): void =>
                                editAssetsRoles(item.hierarchyId)
                            },
                            {
                              buttonLabel: "Edit",
                              color: "secondary",
                              action: (): void =>
                                editAssociatedAssets(item.hierarchyId)
                            },
                            {
                              buttonLabel: "Delete",
                              color: "primary",
                              variant: "contained",
                              action: (): void =>
                                setDeleteDialog(
                                  item.hierarchyId,
                                  item.assetName
                                )
                            }
                          ] : [
                            {
                            buttonLabel: "Roles",
                            color: "primary",
                            variant: "contained",
                            action: (): void =>
                              editAssetsRoles(item.hierarchyId)
                          }
                        ]}
                        >
                          <ViewTreeView
                            data={hierarchyContainer(item)}
                            renderLabel={getHierarchyLabel}
                            getId={getId}
                          />
                        </ExpansionPanel>
                      );
                    })}
                  {isEmpty(userAsset) && (
                    <PaperHeading label="No associated assets on this user." />
                  )}
                </>
              )}
            </Grid>
          </Grid>

          <FloatingActionButtonList>
            {isUpdate && (
              <FloatingActionButton type="submit">
                <SaveIcon />
                Save
              </FloatingActionButton>
            )}
            {!isUpdate && (
              <FloatingActionButton onClick={switchToUpdate}>
                <EditIcon />
                Update
              </FloatingActionButton>
            )}
            <FloatingActionButton onClick={(): void => goToAssociateNewAsset()}>
              <AddIcon />
              New Asset
            </FloatingActionButton>
          </FloatingActionButtonList>

          <DialogModal
            showCancelButton
            title="WARNING: Updating of Country"
            message={`Updating your Country may change your Date Format, Time Format, Timezone, and Unit System Preference. 
            Do you wish to update these fields to the default values related to the selected country?`}
            showModal={showUpdateDefaultConfirmationModal}
            onOk={(): void => {
              changeToDefaultByCountry(values.countryId);
              setShowUpdateDefaultConfirmationModal(false);
            }}
            onHide={(): void => {
              setShowUpdateDefaultConfirmationModal(false);
            }}
            confirmButtonLabel="Confirm"
            cancelButtonLabel="No"
          />
          <DialogModal
            showCancelButton
            title={`WARNING: ${deleteDialogTitle.toUpperCase()} will be removed from this user!`}
            message={`Do you want to remove ${deleteDialogTitle.toUpperCase()} from this user? Note that all associated sections and installations will be removed too.`}
            showModal={associatedAssetDialog}
            onOk={(): void => {
              removeAssociatedAssets();
              setAssociatedAssetDialog(false);
            }}
            onHide={(): void => {
              setAssociatedAssetDialog(false);
            }}
            confirmButtonLabel="Confirm"
            cancelButtonLabel="No"
          />
        </form>
      </>
    </MainBarDashboard>
  );
};

export default UserDetails;
