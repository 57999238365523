import React, { ReactElement, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import MainBarDashboard from "components/MainBarDashboard";
import { IMainTabsProps } from "components/MainTabs/type";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import DialogModal from "components/DialogModal";
import PrivateComponent from "components/PrivateComponent";

import { SpinnerContext } from "provider/SpinnerProvider";
import { AuthContext } from "provider/AuthProvider";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { AdministrationFormPath, AdministrationViewPath } from "routes/paths";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { Column } from "material-table";
import TablePagination, {
  TablePaginationProps,
} from "@material-ui/core/TablePagination";
import Skeleton from "@material-ui/lab/Skeleton";
import Link from "@material-ui/core/Link";

import {
  getConfigurationUsers,
  getDigitalProducts,
  getRoles,
  deleteConfigurationUser,
} from "./Actions";
import {
  IConfigurationUsersResult,
  IFilter,
  IConfigurationUser,
  IConfigurationUserDigitalProduct,
} from "./type";
import { InternalRolePermissions } from "constants/constant";
import { AccessErrorMessages } from "constants/enum";

const useStyles = makeStyles((theme) => ({
  root: {},
  detail: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),

    "& >:not(first-child)": {
      paddingTop: theme.spacing(4),
    },

    "& >:last-child": {
      paddingBottom: theme.spacing(4),
    },
  },
  skeleton: {
    padding: theme.spacing(4),
  },
  skeletonMargin: {
    marginBottom: theme.spacing(2),
  },

  detailLabel: {
    paddingBottom: theme.spacing(1),
    fontWeight: "bolder",
  },

  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const disableLogic = [
  {
    label: "GICAdmin",
    disabledLabels: ["ApplicationSupport"],
  },
  {
    label: "ApplicationSupport",
    disabledLabels: ["GICAdmin", "AdminManager"],
  },
  {
    label: "AdminManager",
    disabledLabels: ["ApplicationSupport"],
  },
];

const AuthorizationOverview = ({ tabs }: IMainTabsProps): ReactElement => {
  const history = useHistory();

  const breadcrumbs = [
    {
      label: "Administration",
    },
  ];

  const goToCreateConfigUser = () => {
    history.push(AdministrationFormPath);
  };

  const DEFAULT_PAGE = 0;
  const DEFAULT_ROWS_PER_PAGE = 10;
  const [selectedUser, setSelectedUser] = useState<IConfigurationUser>();
  const [filter, setFilter] = useState<IFilter>({
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    currentPage: DEFAULT_PAGE,
  } as IFilter);
  const [configurationUsersData, setConfigurationUsersData] = useState<
    IConfigurationUsersResult
  >();

  const [selectedRoles, setSelectedRoles] = useState<{ roleName: string }[]>(
    []
  );
  const [selectedDigitalProducts, setSelectedDigitalProducts] = useState<
    IConfigurationUserDigitalProduct[]
  >([]);
  const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState<IConfigurationUser>();
  const [searchQuery, setSearchQuery] = useState("");
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const { permissionContext } = useContext(AuthContext);
  const [, , hasPermission] = permissionContext;

  const classes = useStyles();

  const fetchConfigUsers = (): Promise<boolean> => {
    return new Promise((resolve) => {
      getConfigurationUsers(
        filter.currentPage,
        filter.rowsPerPage,
        searchQuery
      ).then((result) => {
        setConfigurationUsersData(result);
        resolve(true);
      });
    });
  };

  useEffect(() => {
    fetchConfigUsers();
  }, [filter, searchQuery]);

  useEffect(() => {
    setIsDetailLoading(true);
    if (selectedUser) {
      (async function loadDigitalProducts() {
        await getDigitalProducts(selectedUser.id).then((digitalProducts) => {
          if (selectedUser.objectId) {
            getRoles(selectedUser.objectId).then((roles) => {
              setSelectedRoles(roles);
              setSelectedDigitalProducts(digitalProducts);
              setIsDetailLoading(false);
            });
          }
        });
      })();
    }
  }, [selectedUser]);

  const tablePagination = (props: TablePaginationProps): JSX.Element => {
    return (
      <TablePagination
        className={classes.pagination}
        onChangePage={(e, page): void => {
          setFilter(
            Object.assign({}, filter, {
              currentPage: page,
            } as IFilter)
          );
        }}
        onChangeRowsPerPage={(e): void => {
          if (props.onChangeRowsPerPage) {
            setFilter(
              Object.assign({}, filter, {
                currentPage: DEFAULT_PAGE,
                rowsPerPage: Number(e.target.value),
              } as IFilter)
            );
          }
        }}
        rowsPerPageOptions={props.rowsPerPageOptions}
        count={configurationUsersData ? configurationUsersData.totalRows : 0}
        rowsPerPage={filter.rowsPerPage}
        page={filter.currentPage}
      />
    );
  };

  const details = (user: any): JSX.Element => {
    //set selected user to fire query of roles and digital products from useEffect() method.
    setSelectedUser(user);

    return user.isDetailLoading ? (
      <Container className={classes.skeleton}>
        <Skeleton
          variant="rect"
          height={40}
          className={classes.skeletonMargin}
        />
        <Skeleton variant="rect" height={40} />
      </Container>
    ) : (
      <Container className={classes.detail}>
        <div>
          <div className={classes.detailLabel}>Digital Product</div>
          <div>
            {user.selectedDigitalProducts.length === 0 ? (
              <span>NONE</span>
            ) : (
              user.selectedDigitalProducts
                .map(
                  (item: { digitalProductName: string }) =>
                    item.digitalProductName
                )
                .join(", ")
            )}
          </div>
        </div>
        <div>
          <div className={classes.detailLabel}>User Role</div>
          <div>
          {user.selectedRoles.roles.length === 0 ? (
              <span>NONE</span>
            ) : (
              user.selectedRoles.roles
                .map(
                  (item: { roleName: string }) => item.roleName)
                .join(", ")
            )}
          </div>
        </div>
      </Container>
    );
  };

  const goToConfigUserDetail = (userId: string) => {
    history.push(AdministrationViewPath.replace(":userId", userId));
  };

  const userName = (rowData: IConfigurationUser) => (
    <div style={{ minWidth: 180 }}>
      <Link
        component="button"
        onClick={() => {
          goToConfigUserDetail(rowData.id);
        }}
      >
        {rowData.firstName} {rowData.lastName}
      </Link>
    </div>
  );

  const azureUserID = (rowData: IConfigurationUser) => (
    <div style={{ minWidth: 180 }}>{rowData.objectId}</div>
  );

  const primaryEmail = (rowData: IConfigurationUser) => (
    <div style={{ minWidth: 180 }}>{rowData.email}</div>
  );

  const headerColumns: Array<Column<any>> = [
    {
      title: "Name",
      render: userName,
    },
    {
      title: "Azure Id",
      render: azureUserID,
    },
    {
      title: "Primary Email",
      render: primaryEmail,
    },
  ];

  const onSearch = (keyword: string) => {
    setFilter({
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      currentPage: DEFAULT_PAGE,
    } as IFilter);
    setSearchQuery(keyword);
  };

  return (
    <MainBarDashboard
      search
      searchPlaceholder="Search Config Users"
      onSearch={onSearch}
      breadcrumbs={breadcrumbs}
      tabs={tabs}
      searchPermissions={[
        InternalRolePermissions.MANAGE_CONFIG_USER,
        InternalRolePermissions.VIEW_CONFIG_USER,
      ]}
    >
      <PrivateComponent
        data={configurationUsersData}
        errorMessage={AccessErrorMessages.noAccessToData}
        permissions={[
          InternalRolePermissions.MANAGE_CONFIG_USER,
          InternalRolePermissions.VIEW_CONFIG_USER,
        ]}
      >
        <MaterialTable
          key={
            configurationUsersData?.data
              ? configurationUsersData?.data.length
              : "configUserTable"
          }
          columns={headerColumns}
          data={configurationUsersData?.data || []}
          options={{
            sorting: false,
            actionsColumnIndex: -1,
            filtering: false,
            toolbar: false,
            pageSizeOptions: [10, 15, 20],
            pageSize: filter.rowsPerPage,
            showEmptyDataSourceMessage: false,
            headerStyle: {
              padding: "16px",
              fontWeight: "bold",
            },
            detailPanelType: "single",
          }}
          detailPanel={[
            (selectedUser) => {
              selectedUser["selectedRoles"] = selectedRoles;
              selectedUser["selectedDigitalProducts"] = selectedDigitalProducts;
              selectedUser["isDetailLoading"] = isDetailLoading;
              return {
                tooltip: "Show Details",
                render: (selectedUser) => details(selectedUser),
              };
            },
          ]}
          components={{
            Pagination: tablePagination,
          }}
          actions={
            hasPermission([InternalRolePermissions.MANAGE_CONFIG_USER])
              ? [
                {
                  icon: "delete",
                  tooltip: "Delete User",
                  onClick: (event, rowData) => {
                    setDeleteDialog(true);
                    setUserToDelete(rowData);
                  },
                },
              ]
              : []
          }
        />
      </PrivateComponent>

      <FloatingActionButtonList>
        <PrivateComponent
          permissions={[InternalRolePermissions.MANAGE_CONFIG_USER]}
        >
          <FloatingActionButton onClick={goToCreateConfigUser}>
            <GroupAddIcon />
            Create
          </FloatingActionButton>
        </PrivateComponent>
      </FloatingActionButtonList>

      <DialogModal
        showCancelButton
        title="WARNING: Deletion of User!"
        message={`Are you sure you want to delete this user (${userToDelete?.firstName} ${userToDelete?.lastName})?`}
        showModal={deleteDialog}
        onOk={(): void => {
          setIsSpinnerVisible(true);
          setDeleteDialog(false);
          deleteConfigurationUser(userToDelete?.id).then(
            (successDeleteUser: boolean) => {
              if (successDeleteUser) {
                fetchConfigUsers().then((successFetchUsers: boolean) => {
                  if (successFetchUsers) {
                    setIsSpinnerVisible(false);
                  }
                });
              }
            }
          );
          setUserToDelete(undefined);
        }}
        onHide={(): void => {
          setDeleteDialog(false);
          setUserToDelete(undefined);
        }}
        confirmButtonLabel="Confirm"
        cancelButtonLabel="No"
      />
    </MainBarDashboard>
  );
};

export default AuthorizationOverview;
