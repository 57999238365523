import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  fade,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";

import Collapse from "@material-ui/core/Collapse";
import ListAltIcon from "@material-ui/icons/ListAlt";
import IconButton from "@material-ui/core/IconButton";
import WarningIcon from "@material-ui/icons/Warning";
import AddIcon from "@material-ui/icons/Add";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import TreeItem from "@material-ui/lab/TreeItem";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BuildIcon from "@material-ui/icons/Build";
import { TransitionProps } from "@material-ui/core/transitions";

import DialogModal from "components/DialogModal";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support

import { AssetType } from "constants/enum";
import { IHierarchy } from "components/type";
import { HierarchiesTable as HierarchiesTableConst } from "constants/constant";

import { ModalContext } from "provider/ModalProvider";
import { SectionContext } from "provider/SectionProvider";
import { AccountContext } from "provider/AccountProvider";
import { InstallationContext } from "provider/InstallationProvider";
import { SpinnerContext } from "provider/SpinnerProvider";

import styled from "styled-components";
import { getAccount } from "../../../actions/accountsPageActions";

import { patchUnAssignInstallation } from "../../../actions/installationsPageActions";

import {
  SectionUpdatePath,
  AccountsUpdatePath,
  InstallationsUpdatePath,
} from "routes/paths";
const TransitionComponent = (props: TransitionProps) => {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
};

const TreeItemContainer = styled.div`
  position: relative;
`;
const ButtonsContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  float: right;
`;

const CreateUpdateTreeItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    iconContainer: {
      "& .close": {
        opacity: 0.3,
      },
    },
    label: {
      padding: 10,
    },
    group: {
      marginLeft: 12,
      paddingLeft: 12,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
  })
)((props: any) => {
  const history = useHistory();

  const { sectionModalContext, installationModalContext } = useContext(
    ModalContext
  );

  const [, setShowSectionModal] = sectionModalContext;
  const [, setShowInstallationModal] = installationModalContext;

  const { updateSectionContext } = useContext(SectionContext);
  const [, setSection] = updateSectionContext;

  const { updateAccountContext } = useContext(AccountContext);
  const [account, setAccount] = updateAccountContext;

  const { modalInstallationContext } = useContext(InstallationContext);
  const [, setModalInstallation] = modalInstallationContext;

  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const modalObjects = {
    rootId: account.id,
    parentId: "",
    isCreate: true,
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props: any) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const showSectionModal = (nodeId: string) => {
    setAnchorEl(null);
    modalObjects.parentId = nodeId;
    setSection(modalObjects);

    setShowSectionModal(true);
  };

  const showInstallationModal = (nodeId: string) => {
    setAnchorEl(null);
    modalObjects.parentId = nodeId;
    setModalInstallation(modalObjects);
    setShowInstallationModal(true);
  };

  const confirmDelete = async (id: string) => {
    setIsSpinnerVisible(true);
    setShowConfirmation(false);
    const acc = await getAccount(id);

    const installationDetails: IHierarchy = {
      id: id,
      rootId: acc.id,
      parentId: acc.parentId,
      ownerAccountId: acc.ownerAccountId,
      typeId: acc.typeId,
      typeName: acc.typeName,
      assetName: acc.assetName,
      customerNumber: acc.customerNumber,
      countryId: acc.countryId,
    };
    patchUnAssignInstallation(installationDetails).then((e) => {
      setIsSpinnerVisible(false);

      if (props.deletecallback) {
        props.deletecallback();
      }
    });
  };

  const RowClick = async (
    accountId: string | undefined,
    accountType: string
  ) => {
    if (!accountId) {
      return;
    }
    setIsSpinnerVisible(true);
    const acc = await getAccount(accountId);
    switch (accountType) {
      case AssetType.account:
        setAccount(acc);
        setIsSpinnerVisible(false);
        return history.push(AccountsUpdatePath.replace(":id", accountId));
      case AssetType.section:
        setSection(acc);
        setIsSpinnerVisible(false);
        return history.push(SectionUpdatePath.replace(":id", accountId));
      case AssetType.installation:
        setModalInstallation(acc);       
        if (acc.installationDetail) {
          const installationId = acc.installationDetail.installationId;
          setIsSpinnerVisible(false);
          return history.push(           
            InstallationsUpdatePath.replace(":installationId", installationId)
          );
        }
        return;    
      default:
        setIsSpinnerVisible(false);
        break;
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TreeItemContainer>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={(): void => showSectionModal(props.nodeId)}>
          <ListItemIcon>
            <LocationCityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Section" />
        </MenuItem>
        <MenuItem onClick={(): void => showInstallationModal(props.nodeId)}>
          <ListItemIcon>
            <BuildIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Installation" />
        </MenuItem>
      </StyledMenu>
      <TreeItem {...props} TransitionComponent={TransitionComponent} />
      <ButtonsContainer>
        {props.type !== AssetType.installation && props.isParent && props.isAdmin && (
          <IconButton
            aria-label="add"
            color="default"
            aria-controls="customized-menu"
            aria-haspopup="true"
            size="small"
            onClick={handleClick}
          >
            <AddIcon />
          </IconButton>
        )}
        {/* Temporarily Removed to prevent moving assets */}
        {/* {props.type !== AssetType.account && (
          <Tooltip
            title={
              props.type === AssetType.installation
                ? "Detach installation"
                : "Detach section"
            }
          >
            <IconButton
              aria-label="delete"
              size="small"
              disabled
              onClick={(): void => setShowConfirmation(true)}
            >
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>
        )} */}
        <Tooltip title={`View ${props.type.toLowerCase()} details`}>
          <IconButton
            aria-label="info"
            size="small"
            onClick={() => RowClick(props.nodeId, props.type)}
          >
            <ListAltIcon />
          </IconButton>
        </Tooltip>
      </ButtonsContainer>
      <DialogModal
        showCancelButton
        title="Detach"
        message={`${
          props.type === AssetType.account || props.type === AssetType.section
            ? `This ${props.type} ${HierarchiesTableConst.DIALOG_MESSAGE_ACCOUNT_SECTION} Do you want to continue?`
            : `${HierarchiesTableConst.DIALOG_MESSAGE_INSTALLATION}/detach: ${props.label}?`
        }`}
        showModal={showConfirmation}
        onOk={() => confirmDelete(props.nodeId)}
        onHide={(): void => setShowConfirmation(false)}
        icon={<WarningIcon fontSize="large" color="secondary" />}
      />
    </TreeItemContainer>
  );
});

export default CreateUpdateTreeItem;
