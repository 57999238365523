import useLoggedInUserDetails from 'hooks/useLoggedInUserDetails';
import React from 'react';

interface WithAuthorizationWrapperProps {
    children: React.ReactNode;
}

const withAuthorizationWrapper = (WrappedComponent: React.ComponentType) => {
    const WithAuthorization: React.FC<WithAuthorizationWrapperProps> = (props) => {
        const { isAdmin } = useLoggedInUserDetails();

        if (!isAdmin) {
            return null; // or you can return a fallback UI
        }

        return <WrappedComponent {...props} />;
    };

    return WithAuthorization;
};

export default withAuthorizationWrapper;