import React, { useState, useEffect, useContext, Fragment } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MaterialTableProps } from "material-table";
import TextField from "@material-ui/core/TextField";
import TablePagination, {
  TablePaginationProps,
} from "@material-ui/core/TablePagination";
import Skeleton from "@material-ui/lab/Skeleton";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Warning, ListAlt } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import SubscriptionDetails from "../SubscriptionDetails";

import {
  SubscriptionsTableProps,
  SubscriptionData,
  SubscriptionUpdateData,
} from "../type";
import { TableProps, ErrorParams } from "./type";
import { SpinnerContext } from "provider/SpinnerProvider";
import { createSubscription } from "../Actions";
import DialogModal from "components/DialogModal";

import { toMonth, prependZeroToSingleDigits } from "../../../utils";
import useLoggedInUserDetails from "hooks/useLoggedInUserDetails";

const matEditTextField = (props: any): JSX.Element => {
  return (
    <TextField
      size="small"
      fullWidth
      required={props.columnDef.required ? true : false}
      value={props.value ? props.value : ""}
      error={props.columnDef.error ? true : false}
      helperText={props.columnDef.title}
      onChange={(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ): void => props.onChange(e.target.value)}
    />
  );
};

const generateTooltipBody = (title: string) => {
  return (
    <p>
      <Warning /> {title}
    </p>
  );
};

const installationNameCell = (rowData: any) => {
  if (rowData.status === false) {
    const reason = `Not active: ${rowData.reason}`;
    return (
      <Tooltip title={reason}>
        {generateTooltipBody(rowData.installationName)}
      </Tooltip>
    );
  }

  return rowData.installationName;
};

const lastUpdatedDateTimeCell = (rowData: any) => {
  if (rowData.chargedIdUpdatedAt) {
    const date = new Date(rowData.chargedIdUpdatedAt);

    return `${date.getUTCFullYear()}-${toMonth(
      date.getUTCMonth()
    )}-${prependZeroToSingleDigits(
      date.getUTCDate()
    )} ${prependZeroToSingleDigits(
      date.getUTCHours()
    )}:${prependZeroToSingleDigits(date.getUTCMinutes())}`;
  }

  return rowData.chargedIdUpdatedAt;
};

const getInfoIcon = () => {
  return <ListAlt />;
};

const getValidationErrors = (data: any): ErrorParams => {
  return {
    orderRefId: !data.orderReferenceId,
  };
};

const SubscriptionsTable = ({
  onUpdateRow,
  subscriptionsData,
  onPageChange,
  currentPage,
  onChangeRowsPerPage,
  rowsPerPage,
}: SubscriptionsTableProps): JSX.Element => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        display: "contents",
      },
    })
  );
  const classes = useStyles();

  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [isPageChanging, setIsPageChanging] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<
    SubscriptionData
  >();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { isAdmin } = useLoggedInUserDetails();

  const skeletonLoading = (props: MaterialTableProps<object>): JSX.Element => {
    return (
      <TableBody>
        {[...Array(rowsPerPage)].map((num: number, index: number) => (
          <TableRow key={`skeletonKey${index}`}>
            {[...Array(props.columns.length + 1)].map(
              (n: number, i: number) => (
                <TableCell key={`skeletonCellKey${i}`}>
                  <Skeleton />
                </TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const tablePagination = (props: TablePaginationProps): JSX.Element => {
    return (
      <TablePagination
        className={classes.root}
        onChangePage={(e, page): void => {
          setIsPageChanging(true);
          onPageChange(page);
        }}
        onChangeRowsPerPage={(e): void => {
          if (props.onChangeRowsPerPage) {
            props.onChangeRowsPerPage(e);
          }
        }}
        rowsPerPageOptions={props.rowsPerPageOptions}
        count={subscriptionsData ? subscriptionsData.totalRows : 0}
        rowsPerPage={rowsPerPage}
        page={currentPage}
      />
    );
  };

  const columns = (error: ErrorParams | undefined = undefined): any[] => [
    {
      title: "Order Ref ID",
      field: "orderReferenceId",
      error: error?.orderRefId,
      required: true,
      editComponent: matEditTextField,
    },
    { title: "Account", field: "accountName", editable: "never" },
    {
      title: "Installation",
      field: "installationName",
      editable: "never",
      render: (rowData: any) => installationNameCell(rowData),
    },
    {
      title: "Digital Offering",
      field: "digitalOfferingName",
      editable: "never",
    },
    {
      title: "SAP Product Number",
      field: "digitalOfferingSAPNumber",
      editable: "never",
    },
    {
      title: "Subscription ID",
      field: "zuoraSubscriptionId",
      editable: "never",
    },
    {
      title: "Subscription Charge ID",
      field: "chargeId",
      editable: "never",
    },
    {
      title: "Last Updated Date (UTC)",
      field: "chargedIdUpdatedAt",
      editable: "never",
      render: (rowData: any) => lastUpdatedDateTimeCell(rowData),
    },
  ];

  const [state, setState] = useState<TableProps>({
    columns: columns(),
    data: [],
  });

  const sendData = (
    newData: SubscriptionData,
    oldData: SubscriptionData
  ): void => {
    const subscriptionData: SubscriptionUpdateData = {
      installationId: newData.installationId,
      digitalOfferingId: newData.digitalOfferingId,
      hierarchyId: newData.accountId,
      orderReferenceId: newData.orderReferenceId,
      zuoraSubscriptionId: newData.zuoraSubscriptionId || "",
      chargedId: newData.chargeId || "",
    };

    if (oldData.id) {
      subscriptionData.subscriptionId = oldData.id;
    }

    createSubscription(subscriptionData).then(() => {
      onUpdateRow(currentPage, rowsPerPage);
    });
  };

  useEffect(() => {
    if (subscriptionsData && subscriptionsData.data) {
      setState({ columns: columns(), data: subscriptionsData.data });
      setIsPageChanging(false);
    }
  }, [subscriptionsData]);

  const hasData = () => typeof subscriptionsData !== "undefined";

  const tableComponents = () =>
    hasData() && !isPageChanging
      ? {
        Pagination: tablePagination,
      }
      : {
        Pagination: tablePagination,
        Body: skeletonLoading,
      };

  return (
    <Fragment>
      <MaterialTable
        title="Subscriptions"
        columns={state.columns}
        data={state.data}
        isLoading={false}
        options={{
          sorting: false,
          actionsColumnIndex: -1,
          filtering: false,
          toolbar: false,
          pageSizeOptions: [10, 15, 20, 100],
          pageSize: rowsPerPage,
          showEmptyDataSourceMessage: false,
          headerStyle: {
            padding: "16px",
            fontWeight: "bold",
          },
        }}
        actions={[
          {
            icon: () => getInfoIcon(),
            tooltip: "View Details",
            onClick: (event, rowData: any) => {
              setSelectedSubscription(rowData as SubscriptionData);
              setShowSubscriptionModal(true);
            },
          },
        ]}
        onChangeRowsPerPage={(p: number): void => {
          onChangeRowsPerPage(p);
        }}
        editable={{
          isEditable: () => isAdmin,
          onRowUpdate: (newData, oldData): Promise<any> => {
            setIsSpinnerVisible(true);
            const errors = getValidationErrors(newData);

            setState({
              columns: columns({
                orderRefId: errors.orderRefId,
              }),
              data: state.data,
            });

            return new Promise<void>((resolve, reject): void => {
              if (errors.orderRefId) {
                setIsSpinnerVisible(false);
                reject();
              } else {
                if (oldData) {
                  setState((prevState) => {
                    const tableData = [...prevState.data];
                    tableData[tableData.indexOf(oldData)] = newData;
                    return { ...prevState, tableData };
                  });

                  sendData(newData, oldData);

                  resolve();
                }
              }
            });
          },
        }}
        components={tableComponents()}
      />
      <DialogModal
        showCancelButton={false}
        showOkButton={true}
        title={"Subscription Info"}
        content={<SubscriptionDetails subscription={selectedSubscription} />}
        showModal={showSubscriptionModal}
        onOk={(): void => setShowSubscriptionModal(false)}
        onHide={(): void => setShowSubscriptionModal(false)}
        maxWidth="md"
      />
    </Fragment>
  );
};

export default SubscriptionsTable;
