import React, { useEffect, useState } from "react";
import PaperHeading from "components/PaperHeading";
import AutoComplete from "../../../../../components/AutoComplete";
import { Paper, TextField, TextFieldProps } from "@material-ui/core";
import { DeviceReference } from "../type";
import {
  getAllDevices,
  getDevice
} from "../../../../../actions/devicePageActions";
import MaterialTable, { Column } from "material-table";
import DialogModal from "components/DialogModal";

const getSearchDevices = (query = "") =>
  getAllDevices(undefined, undefined, query)
    .then((d) => d.data)
    .catch(() => []);

const InputField = (props: TextFieldProps) => (
  <TextField
    disabled
    fullWidth
    style={{ margin: "8px 0" }}
    InputProps={{ readOnly: true }}
    {...props}
  />
);

const stringify = (val = "") => val || "";

const ReferencesSection = ({
  deviceReferencesList,
  onAdd,
  onRemove
}: {
  deviceReferencesList: DeviceReference[];
  onAdd: (item: DeviceReference) => void;
  onRemove: (item: DeviceReference) => void;
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [autocompleteDeviceIdReset, setAutocompleteDeviceIdReset] =
    useState<number>();
  const [selectedDevice, setSelectedDevice] = useState<
    DeviceReference | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const columns: Column<DeviceReference>[] = [
    { title: "Device Name", field: "deviceName" },
    { title: "Device Twin ID", field: "deviceTwinId" },
    {
      title: "Device Product Name",
      field: "deviceProductName"
    }
  ];

  const containerOverride = (props: any) => <div {...props}></div>;

  useEffect(() => {
    setAutocompleteDeviceIdReset(Date.now());
  }, []);

  const setDeviceId = (devId: string) => {
    getDevice(devId).then((device: DeviceReference) => {
      setSelectedDevice(device);
      setIsLoading(false);
    });
  };

  const updateDevice = (devId: string) => {
    setIsLoading(true);
    if (devId && devId !== "") {
      setDeviceId(devId);
    } else {
      setSelectedDevice(undefined);
      setIsLoading(false);
    }
  };

  return (
    <Paper>
      <MaterialTable
        components={{
          Container: containerOverride
        }}
        title={<PaperHeading label="References" />}
        options={{
          search: true,
          paging: false,
          toolbar: true,
          sorting: true,
          maxBodyHeight: 450,
          actionsColumnIndex: -1
        }}
        columns={columns}
        data={deviceReferencesList}
        actions={[
          {
            icon: "add_box",
            tooltip: "Add Reference",
            isFreeAction: true,
            onClick: () => setShowDialog(true)
          },
          (rowData) => ({
            icon: "delete",
            tooltip: "Delete reference",
            onClick: (event, rowData) => {
              setSelectedDevice(rowData as DeviceReference);
              setShowRemoveDialog(true);
            }
          })
        ]}
      />
      <DialogModal
        showCancelButton
        title="Add Reference"
        content={
          <div>
            <AutoComplete
              error={false}
              label="Device Name"
              name="deviceId"
              labelName="deviceName"
              valueName="id"
              getData={getSearchDevices}
              inputRef={null}
              reset={autocompleteDeviceIdReset}
              onChange={updateDevice}
              defaultValue={null}
            />
            <InputField
              InputProps={{ readOnly: true }}
              label="Device Twin ID"
              value={stringify(selectedDevice?.deviceTwinId)}
            />
            <InputField
              InputProps={{ readOnly: true }}
              label="Device Product Name"
              value={stringify(selectedDevice?.deviceProductName)}
            />
          </div>
        }
        showModal={showDialog}
        onOk={(): void => {
          if (selectedDevice) {
            onAdd(selectedDevice);
          }
          setShowDialog(false);
        }}
        onHide={(): void => {
          setShowDialog(false);
        }}
        confirmButtonLabel="OK"
        cancelButtonLabel="Cancel"
        disableOk={isLoading}
      />
      <DialogModal
        showCancelButton
        title="Remove reference"
        message={`Are you sure you want to remove reference: ${selectedDevice?.deviceName}?`}
        showModal={showRemoveDialog}
        onOk={(): void => {
          if (selectedDevice) {
            onRemove(selectedDevice);
          }
          setShowRemoveDialog(false);
        }}
        onHide={(): void => setShowRemoveDialog(false)}
      />
    </Paper>
  );
};

export default ReferencesSection;
