import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "provider/UserProvider";
import { SpinnerContext } from "provider/SpinnerProvider";
import {
  getUsers,
  deleteUser,
  getUser,
} from "../../../actions/usersPageActions";
import AddIcon from "@material-ui/icons/Add";
import MainBarDashboard from "components/MainBarDashboard";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import EnhancedPaginatedTable from "components/EnhancedPaginatedTable";
import { IHeadCell } from "components/EnhancedPaginatedTable/type";
import DialogModal from "components/DialogModal";

import { Delete } from "@material-ui/icons";

import { UsersCreatePath, UsersUpdatePath } from "routes/paths";
import useLoggedInUserDetails from "hooks/useLoggedInUserDetails";

const UserList = () => {
  const { isAdmin } = useLoggedInUserDetails();
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const { updateUserContext } = useContext(UserContext);
  const [, setUser] = updateUserContext;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [refresh, setRefresh] = useState<number>();
  const [query, setQuery] = useState("");

  const breadcrumbs = [{ label: "Users" }];

  const history = useHistory();
  const goToUsersForm = () => {
    setUser({});
    history.push(UsersCreatePath);
  };

  const goToUserDetails = async (data: { [i: string]: any }) => {
    setIsSpinnerVisible(true);
    const user = await getUser(data.id);
    setUser(user);
    setIsSpinnerVisible(false);
    history.push(UsersUpdatePath.replace(":userId", data.id));
  };

  const onDelete = (id = "") => {
    deleteUser(id).then(() => {
      setRefresh(Date.now());
    });
  };

  const deleteUserAction = (obj: { [k: string]: any }) => {
    setDeleteDialog(true);
    setUserId(obj.id);
  };

  const onSearch = (keyword: string): void => {
    const searchTerm = keyword.trim();
    setQuery(searchTerm);
  };

  const userActionsColumn: IHeadCell = {
    id: "actionId",
    disablePadding: false,
    label: "Action",
    hasAction: true,
    actions: [{
      onSubmit: deleteUserAction,
      icon: Delete,
      label: "Hierarchy",
      referenceIds: ["id"],
    }],
  };

  let headCells: IHeadCell[] = [
    {
      id: "id",
      disablePadding: false,
      label: "ID",
      fieldName: "id",
      hidden: true,
    },
    {
      id: "fullName",
      disablePadding: false,
      label: "Name",
      fieldName: "fullName",
      isClickable: true,
      onClick: goToUserDetails,
      referenceIds: ["id"],
    },
    {
      id: "userStatus",
      disablePadding: false,
      label: "Status",
      fieldName: "userStatus",
    },
    {
      id: "tamId",
      disablePadding: false,
      label: "ID",
      fieldName: "tamId",
    },
    {
      id: "primaryPhone",
      disablePadding: false,
      label: "Primary Phone",
      fieldName: "primaryPhone",
    },
    {
      id: "primaryEmail",
      disablePadding: false,
      label: "Primary Email",
      fieldName: "primaryEmail",
    },
    {
      id: "company",
      disablePadding: false,
      label: "Company",
      fieldName: "company",
    },
    {
      id: "notificationPreference",
      disablePadding: false,
      label: "Notification Preference",
      fieldName: "notificationPreference",
    },
  ];

  if (isAdmin) {
    headCells = [...headCells, userActionsColumn];
  }

  useEffect(() => {
    setRefresh(Date.now());
  }, []);

  return (
    <MainBarDashboard
      search
      searchPlaceholder="Search User"
      onSearch={onSearch}
      breadcrumbs={breadcrumbs}
    >
      <EnhancedPaginatedTable
        getData={getUsers}
        headCells={headCells}
        refresh={refresh}
        query={query}
      />
      <FloatingActionButtonList>
        <FloatingActionButton onClick={goToUsersForm}>
          <AddIcon /> New User
        </FloatingActionButton>
      </FloatingActionButtonList>
      <DialogModal
        showCancelButton
        title="WARNING: Deletion of User!"
        message={`Deleting of users may cause also remove assets associated on this record. Do you wish to delete this user?`}
        showModal={deleteDialog}
        onOk={(): void => {
          setDeleteDialog(false);
          onDelete(userId);
        }}
        onHide={(): void => {
          setDeleteDialog(false);
        }}
        confirmButtonLabel="Confirm"
        cancelButtonLabel="No"
      />
    </MainBarDashboard>
  );
};

export default UserList;
