import React from "react";
import PropTypes from "prop-types";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import withAuthorizationWrapper from "components/withAuthorizationWrapper";

const FloatingActionButtonList: React.FC = ({
  children
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        position: "fixed",
        bottom: "25px",
        right: "20px",
        display: "flex",
        flexDirection: "column",
        zIndex: 999
      }
    })
  );
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

FloatingActionButtonList.propTypes = {
  children: PropTypes.node.isRequired
};

export default withAuthorizationWrapper(FloatingActionButtonList);
