import React from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDial from "@material-ui/lab/SpeedDial";
import withAuthorizationWrapper from "components/withAuthorizationWrapper";
import PropTypes from "prop-types";

const SpeedDialList: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [open, setOpen] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      height: 380,
      transform: "translateZ(0px)",
      flexGrow: 1,
    },
    speedDial: {
      position: "fixed",
      bottom: "25px",
      right: "20px",
      display: "flex",
    },
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e: any) => {
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <SpeedDial
      ariaLabel="Create Asset"
      className={classes.speedDial}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
    >
      {children}
    </SpeedDial>
  );
};

SpeedDialList.propTypes = {
  children: PropTypes.node.isRequired
};


export default withAuthorizationWrapper(SpeedDialList);
