import React, { useContext, useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import CreateUpdateTreeItem from "./CreateUpdateTreeItem/CreateUpdateTreeItem";
import CloseSquare from "../../svg/CloseSquare";
import MinusSquare from "../../svg/MinusSquare";
import PlusSquare from "../../svg/PlusSquare";

import { AccountContext } from "provider/AccountProvider";
import { SectionContext } from "provider/SectionProvider";
import { IAsset } from "components/type";

import { getAccount } from "../../actions/accountsPageActions";
import useLoggedInUserDetails from "hooks/useLoggedInUserDetails";

const useStyles = makeStyles(
  createStyles({
    root: {
      minHeight: 264,
      flexGrow: 1,
    },
  })
);

const CreateUpdateTreeView = (): JSX.Element => {
  const classes = useStyles();
  const { isAdmin } = useLoggedInUserDetails();
  const { updateAccountContext } = useContext(AccountContext);
  const { loadSectionContext } = useContext(SectionContext);

  const [assets, setUpdateAccount] = updateAccountContext;
  const [currentSection] = loadSectionContext;

  const [expandedList, setExpanded] = useState<string[]>([]);
  const nodeList: string[] = [];

  const insertExpandedNode = (items: any) => {
    nodeList.push(items?.hierarchyId);
    if (items) {
      items.children.map((items: any) => {
        nodeList.push(items.hierarchyId);
        if (items && items.children?.length > 0) {
          insertExpandedNode(items);
        }
      });
    }
    setExpanded([...nodeList]);
  };

  useEffect(() => {
    insertExpandedNode(assets.hierarchy);
  }, [assets]);

  const nodeToggle = (event: object, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  let isParent = false;
  const createTree = (data: IAsset) => {
    const { hierarchyId, assetName, children, typeName } = data;
    const reloadTree = (): void => {
      getAccount(assets.id).then((res) => {
        if (res) {
          setUpdateAccount(res);
        }
      });
    };

    const handleParent = () => {
      if (!isParent) {
        isParent =
          currentSection.id === hierarchyId || assets.id === hierarchyId
            ? true
            : false;
      }
      return currentSection.id === hierarchyId || assets.id === hierarchyId
        ? true
        : isParent;
    };

    return (
      <CreateUpdateTreeItem
        key={hierarchyId}
        nodeId={hierarchyId}
        label={`${assetName} (${typeName})`}
        type={typeName}
        deletecallback={reloadTree}
        isParent={handleParent()}
        isAdmin={isAdmin}
      >
        {children &&
          children.map((item: IAsset) => {
            if (item.children && item.children.length > 0) {
              return createTree(item);
            } else {
              isParent = true;
              return (
                <CreateUpdateTreeItem
                  key={item.hierarchyId}
                  nodeId={item.hierarchyId}
                  label={`${item.assetName} (${item.typeName})`}
                  type={item.typeName}
                  deletecallback={reloadTree}
                  isParent={handleParent()}
                />
              );
            }
          })}
      </CreateUpdateTreeItem>
    );
  };

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={expandedList}
      expanded={expandedList}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      onNodeToggle={nodeToggle}
    >
      {assets.hierarchy && createTree(assets.hierarchy)}
    </TreeView>
  );
};
export default CreateUpdateTreeView;
