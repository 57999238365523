import { authContext } from 'clientApp/configAdal';
import { getAdminUser } from 'components/Actions';
import { InternalRoles } from 'constants/constant';
import { useState, useEffect, useCallback } from 'react';

interface UserDetails {
  isAdmin: boolean;
}

const useLoggedInUserDetails = (): UserDetails => {
  const context: any = authContext;

  const [roles, setRoles] = useState<string[]>([]);

  const fetchData = useCallback(async (): Promise<void> => {
    if (context.account.accountIdentifier) {
      getAdminUser(context.account.accountIdentifier).then(({ roles }: { roles: string[] }) => {
        setRoles(roles.map(role => role.toLowerCase()));
      });
    }
  }, [context.account.accountIdentifier]);

  useEffect(() => {
    fetchData();
  }, [context]);

  return { isAdmin: roles.includes(InternalRoles.ADMIN_MANAGER.toLowerCase()) };
};

export default useLoggedInUserDetails;