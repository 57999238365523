import React, { useState, useEffect, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import HierarchiesTable from "./Table/HierarchiesTable";

import HierarchyModal from "./HierarchyModal/HierarchyModal";
import { IOverviewProps } from "components/type";
import MainBarDashboard from "components/MainBarDashboard";
import { AccountOverview as AccountOverviewConst } from "constants/constant";

import {
  AccountsCreatePath,
  SectionCreatePath,
  InstallationsCreatePath,
} from "routes/paths";

import SpeedDialList from "components/SpeedDial";

import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BuildIcon from "@material-ui/icons/Build";

const AccountOverview = ({
  refreshTimestamp,
}: IOverviewProps): ReactElement => {

  const [searchKey, setSearchKey] = useState<string>("");
  const history = useHistory();
  useEffect(() => {
    return;
  }, [refreshTimestamp]);

  const goToAccountForm = (): void => {
    history.push(AccountsCreatePath);
  };

  const goToSectionForm = (): void => {
    history.push(SectionCreatePath);
  };

  const goToInstallationForm = (): void => {
    history.push(InstallationsCreatePath);
  };

  const onSearch = (keyword: string): void => {
    const searchTerm = keyword.trim();
    setSearchKey(searchTerm);
  };

  const breadcrumbs = [
    {
      label: AccountOverviewConst.BREADCRUMBS_LABEL,
    },
  ];

  const actions = [
    {
      icon: <AccountBalanceIcon />,
      name: "Account",
      onClick: () => goToAccountForm(),
    },
    {
      icon: <ApartmentIcon />,
      name: "Section",
      onClick: () => goToSectionForm(),
    },
    {
      icon: <BuildIcon />,
      name: "Installation",
      onClick: () => goToInstallationForm(),
    },
  ];

  return (
    <MainBarDashboard
      search
      searchPlaceholder={AccountOverviewConst.SEARCH_PLACEHOLDER}
      onSearch={onSearch}
      breadcrumbs={breadcrumbs}
    >
      <HierarchiesTable keyword={searchKey} />
      <HierarchyModal />
      <SpeedDialList>
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.onClick}
          />
        ))}
      </SpeedDialList>
    </MainBarDashboard>
  );
};

export default AccountOverview;
